

.trip_photos_others_container{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}
.trip_photos_others_container_last{
    background-color: var(--mainGreen);
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.trip_photos_others_container_last > img {
    opacity: 0.20;
    filter: alpha(opacity=20); 
    position: relative;
    z-index: 1;
}

.socialPhotoImg{
    height: 220px;
}

.photoDeleteIcon{
    display: none;
    cursor: pointer;
    z-index: 3;
    top: 9px;
    height: 13%;
    right: 16px;
    text-align: right;
    position: absolute;
    width: 20%;
}

.photoDeleteThumbs:hover .photoDeleteIcon{
    display: inline;
    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}


 .photoDeleteThumbs {
	position:relative;
    margin-right: 5px;
    margin-left: 5px;
 }


@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}