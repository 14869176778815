.steps_timeline_hm .step {
    position: relative;
}


.steps_timeline_hm .step .body {
    padding-bottom: 30px;
}

.steps_timeline_hm .step:before {
    content: '';
    display: block;
    position: absolute;
    left: -24px;
    width: 4px;
    top: 52%;
    height: 100%;
    background: var(--mainGreen);
}


.steps_timeline_hm .step_day {
    position: relative;
}


.steps_timeline_hm .step_day .body {
    padding-bottom: 30px;
}

.steps_timeline_hm .step_day:before {
    content: '';
    display: block;
    position: absolute;
    left: -24px;
    width: 4px;
    top: 40%;
    height: 130%;
    background: var(--mainGreen);
}

.step_last {
    position: relative;
}

.steps_timeline_hm .step_last .body {
    padding-bottom: 30px;
}

.steps_timeline_hm .step_last:before {
    content: '';
    display: block;
    position: absolute;
    left: -24px;
    width: 4px;
    top: 40%;
    height: 17%;
    background: var(--mainGreen);
}

.steps_timeline_hm .title_hm:before {
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    width: 15px;
    top: 52%;
    height: 15px;
    border-radius: 50%;
    background: var(--mainGreen);

}

.steps_timeline_hm .title_hm_last:before {
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    width: 15px;
    top: 61%;
    height: 15px;
    border-radius: 50%;
    background: var(--mainGreen);

}

.steps_timeline_hm .day {
    display: block;
    position: absolute;
    left: -100px;
    top: -50px;
    background: var(--mainWhite);
    text-align: left;
    font: 18px/21px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGreen);
}

.steps_timeline_hm .first_day {
    display: block;
    position: absolute;
    left: -100px;
    top: 0px;
    background: var(--mainWhite);
    text-align: left;
    font: 18px/21px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGreen);
}

.steps_timeline_hm .day_time {
    display: block;
    position: absolute;
    left: -98px;
    top: 50%;
    background: var(--mainWhite);
    text-align: left;
    font: 18px/21px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGreen);
    width: 52px;
    text-align: right;
}

.content_text_container_bottom_black {
    position: absolute;
    left: 25px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #191919 100%) 0% 0% no-repeat padding-box;
}

.content_text_container_bottom_white {
    position: absolute;
    left: 150px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}

.padding_left_right_white {
    padding-left: 8.5%;
    padding-right: 8.5%;
}

.padding_left_20_timeline {
    padding-left: 20px;
}

.padding_left_40_timeline {
    padding-left: 40px;
}

.padding_right_20_timeline {
    padding-right: 20px;
}

.padding_left_150_timeline {
    padding-left: 150px;
}

@media screen and (min-width: 1023px) {

    .content_text_container_bottom_white,
    .content_text_container_bottom_black {
        height: 100px !important;
    }
}

@media screen and (max-width: 1024px) {

    .content_text_container_bottom_white,
    .content_text_container_bottom_black {
        height: 230px !important;
    }
}

@media screen and (max-width: 768px) {

    .content_text_container_bottom_white,
    .content_text_container_bottom_black {
        height: 100px !important;
    }
}

@media screen and (max-width: 500px) {

   

    /* Mobile */
    .content_text_container_bottom_white,
    .content_text_container_bottom_black {
        height: 200px !important;
    }

    .padding_right_20_timeline {
        padding-right: 0;
    }

    .padding_left_right_white {

        padding-left: 0;
        padding-right: 0;
    }

    .content_text_container_bottom_white {
        left: 80px;
    }

    .steps_timeline_hm .day {
        left: -70px;
        font: 12px/21px Futura Hv BT;
    }

    .steps_timeline_hm .day_time {
        left: -55px;
        font: 12px/21px Futura Hv BT;
    }

    .padding_left_150_timeline {
        padding-left: 0px;
    }

    .publicTimelineDays{
        left: 50px;
        margin-top: 20px;
        position: absolute;
        font: 14px/21px Futura Hv BT ;
        width: 100px;
    }
}