.member_link_btn {
    font: 14px/14px Futura Hv BT;
    border-radius: 25px;
    background-color: var(--mainWhite);
    color: var(--mainGrey);
    border: 0;
    margin-right: 15px;
    margin-bottom: 5px;
    width: 110px;
    height: 25px;
    border: 0;

}

.member_link_btn:active,
.member_link_btn:focus,
.member_link_btn:hover {
    font: 14px/14px Futura Hv BT;
    border-radius: 25px;
    background-color: var(--mainGreen);
    color: var(--mainWhite);
    border: 0;
    margin-right: 15px;
    margin-bottom: 5px;
    width: 110px;
    height: 25px;
    border: 0;
}


@media screen and (max-width: 768px) {


    .member_link_btn {

        font: 11px/12px Futura Hv BT !important;
        width: 90px !important;
    }

    .member_link_btn:active,
    .member_link_btn:focus,
    .member_link_btn:hover {

        font: 11px/12px Futura Hv BT !important;
        width: 90px !important;
    }

    .futura_hv_bt_50_72_white {
        text-align: left;
        font: 25px/36px Futura Hv BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_hv_bt_36_72_white {
        text-align: left;
        font: 18px/36px Futura Hv BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_36_72_white {
        text-align: left;
        font: 18px/36px Futura Md BT;
        ;
        letter-spacing: 0;
        color: var(--mainWhite);
    }

    .futura_md_bt_18_72_green {
        text-align: left;
        font: 9px/36px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainGreen);
    }

}