.reviewsUser {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap
}

.reviewsUser .rating {
    flex-grow: 1;
    margin-top: 20px;
}

.reviewsUser .posting {
    flex-grow: 1;
    margin-top: 10px;
}