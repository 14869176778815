.top_bar_container {
  width: 100%;
  display: block;
  position: relative;
  /* opacity: 0.8; */
  border: 0;
}

.top_bar_container::after {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}


.margin_top_20_avatar {
  margin-top: 20px;
}
