.bg-black {
  background-color: var(--frameBlack);
}

.navbar {
  padding-left: 8.5% !important;
  padding-right: 8.5% !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.profile_section_common .card-body {
  border: 0px !important;
}


.img_banner {
  width: 100%;
  min-height: 150px;
  position: fixed;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: 80%;
  -o-object-position: 80%;
}

.img_object_fit {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: 80%;
  -o-object-position: 80%;
}

#root>.home_container {
  margin-top: -60px;
  max-width: 100%;
  background-color: var(--bgBlack);
}


/* image  slider*/
.thumbnail_img {
  min-width: 100%;
  max-height: 250px;
  overflow: hidden;
}



.thumbnail_content {
  overflow: hidden;
  position: relative;
  max-height: 250px;
  z-index: 0;
}






.margin_left_10_last_trip {
  margin-left: 0px;
}

.text_muv {
  color: var(--mainGreen) !important;
}

.menu_link_title_text {
  font: 16px/0px Futura Hv BT;
  letter-spacing: 0;
  color: var(--mainWhite) !important;
  background-color: initial;
  font-weight: bold;
  cursor: pointer !important;
  opacity: 1;
  text-align: center;
  height: 50px;
  margin-top: 15px;
  padding-top: 0;
}

.menu_link_title_text>p {
  vertical-align: middle;
}

.menu_link_title_text:hover {
  color: var(--mainGreen) !important;
  cursor: pointer;
  border-bottom: 8px solid var(--mainGreen);
}


/* menu size */
@media screen and (min-width: 1250px) {
  .futura_lt_bt_18_24_white_menu {
    font: 14px/20px Futura Lt BT;
  }

  .futura_hv_bt_18_24_white {
    text-align: left;
    font: 14px/20x Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_lt_bt_18_24_green_menu {
    font: 14px/24px Futura Lt BT;
  }

  .futura_md_bt_18_20_white_menu {
    font: 14px/20px Futura Md BT;
  }


  .margin_left_30_menu {
    margin-left: 20px;
  }

  .margin_left_20_menu {
    margin-left: 13px;
  }

  .margin_right_30_menu {
    margin-right: 20px;
  }

  .margin_right_20_menu {
    margin-right: 13px;
  }

  .thumbnail_img {
    max-height: 180px;
  }

  .thumbnail_content {
    max-height: 180px;
  }

}

/* menu size */
@media screen and (max-width: 1250px) {


  .futura_lt_bt_18_24_white_menu {
    font: 12px/18px Futura Lt BT;
  }

  .futura_hv_bt_18_24_white {
    text-align: left;
    font: 12px/18px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_lt_bt_18_24_green_menu {
    font: 12px/24px Futura Lt BT;
  }

  .futura_md_bt_18_20_white_menu {
    font: 11px/20px Futura Md BT;
  }

  .margin_left_50 {
    margin-left: 25px;
  }
  .margin_left_30_menu {
    margin-left: 5px;
  }

  .margin_left_20_menu {
    margin-left: 10px;
  }

  .margin_right_50_menu {
    margin-right: 25px;
  }
  .margin_right_30_menu {
    margin-right: 15px;
  }

  .margin_right_20_menu {
    margin-right: 5px;
  }

  .thumbnail_img {
    max-height: 280px;
  }

  .thumbnail_content {
    max-height: 280px;
  }

  .menu_link_title_text {
    font: 14px/0px Futura Hv BT !important;
  }
}

/* mobile size */
@media screen and (max-width: 768px) {

  .futura_lt_bt_18_24_white_menu {
    font: 10px/12px Futura Lt BT;
  }

  .futura_hv_bt_18_24_white {
    text-align: left;
    font: 10px/12px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_lt_bt_18_24_green_menu {
    font: 10px/24px Futura Lt BT;
  }

  .futura_md_bt_18_20_white_menu {
    font: 11px/20px Futura Md BT;
  }

  #google_map_container>div>div {
    height: 250px !important;
  }

  #google_map_main_container {
    margin-bottom: 270px !important;
  }


  .margin_left_80,
  .margin_left_70,
  .margin_left_65,
  .margin_left_60,
  .margin_left_50,
  .margin_left_40,
  .margin_left_35,
  .margin_left_30,
  .margin_left_20,
  .margin_left_15,
  .margin_left_10,
  .margin_left_5 {
    margin-left: 0px;
  }


  .margin_right_5,
  .margin_right_10,
  .margin_right_15,
  .margin_right_20,
  .margin_right_30,
  .margin_right_35,
  .margin_right_40,
  .margin_right_50,
  .margin_right_60,
  .margin_right_65 {
    margin-right: 0px;
  }

  .margin_left_10_last_trip {
    margin-left: 0px;
  }

  .home_favorites_icon{
    width: 10px !important;
    height: 11px !important;
    margin: 2px
  }


  .my_home_faverites_top_btn_container {
    flex: 1;
  }

  .futura_hv_bt_24_30_white {
    text-align: left;
    font: 16px/19px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_hv_bt_24_26_white {
    text-align: left;
    font: 16px/18px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_hv_bt_16_19_green {
    text-align: left;
    font: 13px/14px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainGreen);
    cursor: pointer;
  }

  .futura_md_bt_24_26_white {
    text-align: left;
    font: 16px/18px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_md_bt_24_20_white {
    text-align: left;
    font: 16px/18px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_hv_bt_30_30_white {
    text-align: left;
    font: 18px/18px Futura Hv BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }

  .futura_lt_bt_24_30_white {
    text-align: left;
    font: 16px/19px Futura Lt BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }


  .profile_container,
  .main_body_container {
    padding: 0 !important;
    margin: 0 !important;
  }



  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 0px;
    padding-left: 0px;
  }

  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    width: 100%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .thumbnail_img {
    max-height: 300px;
  }

  .thumbnail_content {
    max-height: 300px;
  }



  #friends_sm_container {
    width: 50%;
  }

  #friends_container {
    display: inline-block !important;
  }

  .text_overflow_ellipsis>div {
    text-align: center;
  }

  .row {
    margin-left: 0px;
    margin-right: 0px
  }

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .margin_top_50 {
    margin-top: 25px;
  }

  .margin_top_40 {
    margin-top: 20px;
  }

  .margin_top_30 {
    margin-top: 15px;
  }

  .margin_top_20 {
    margin-top: 10px;
  }

  .margin_top_10 {
    margin-top: 5px;
  }

  .margin_bottom_50 {
    margin-bottom: 25px;
  }

  .margin_bottom_40 {
    margin-bottom: 20px;
  }

  .margin_bottom_30 {
    margin-bottom: 15px;
  }

  .margin_bottom_20 {
    margin-bottom: 10px;
  }

  .margin_bottom_10 {
    margin-bottom: 5px;
  }

  .my_home_faverites_top_btn {
    height: 32px;
    margin-top: 10px;
    font: 9px/12px Futura Hv BT !important;
    display: inline-block !important;
  }

  .my_home_faverites_top_btn_container {
    text-align: center;
  }
}