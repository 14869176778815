.flightRecapBody{
    margin-right: 20px;
}

.flightRecap{
    margin-top: 20px
}
.flightTotalTimeline {
    position: relative;
    text-align: center;
 } 

 .TotalLine {
    width:80%;
    position: absolute;
    top:44px;
    background-image: linear-gradient(90deg, #DFDFDF, #DFDFDF 45%, transparent 45%, transparent 100%);
    background-size: 13px 1px;
    border: none;
    height:3px;
    z-index: -2;
}


.flightTotal {
    border: 1px solid #DFDFDF;
    height: auto;
}

.recapButtons{
    margin-top: 20px;
     height: 60px;
      width: 200px;
      color: #7F7F7F;
      font: 14px/14px Futura LT BT;
      border: 0px ;  
}

.recapButtons:focus {
    outline: none;
}


@media only screen and (max-width: 900px) {
    .flightMngBody{
            padding: 20px 30px !important
    }

   .flightMngCode{
             padding: 20px 30px !important
   }

   .flightMngCode span {
            margin:0px 16px !important;
   }


}


