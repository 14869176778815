.videoSlider .slick-slide {
 
  }
.videoDeleteIcon{
    display: none;
    cursor: pointer;
    z-index: 3;
    top: 9px;
    height: 13%;
    right: 20px;
    text-align: right;
    position: absolute;
    width: 20%;
}

.videoDeleteThumbs:hover .videoDeleteIcon{
    display: inline;
    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}


 .videoDeleteThumbs {
	position:relative;
    margin-left: 5px;
    margin-right: 5px;
 }


@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}