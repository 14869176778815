.tripsMember .tripNameField input{
    width: 41vw;
  }

@media screen and (max-width: 768px) {
    .futura_md_bt_19_70_green {
        text-align: left;
        font: 9px/35px Futura Md BT;
        letter-spacing: 0;
        color: var(--mainGreen);
    }

    .futura_hv_bt_30_70_white {
        text-align: left;
        font: 15px/35px Futura Hv BT;
        letter-spacing: 0;
        color: var(--mainWhite);
    }
    .tripsMember .tripNameField input{
        width: 88vw;
      }
}

