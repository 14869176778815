.landing-step-container {
  display: flex;
  width: 100%;
  position: relative;
  bottom: 90px;
  padding-left: 20px;
  padding-right: 20px;
}

.landing-step-container:nth-child(even) {
  justify-content: flex-start;
}
.landing-step-container:nth-child(odd) {
  justify-content: flex-end;
}

.step-description-section {
  position: relative;
  width: 70%;
  height: 100%;
}

.step-description {
  position: absolute;
  top: 205px;
  left: 66px;
}
.step-number-influencers {
  font-size: 270px;
  font-family: monospace;
  background: -webkit-linear-gradient(#ED6A5F 0%, #ffffff 78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step-title-influencer {
  font: 25px/35px Futura Hv BT;
  letter-spacing: 0;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px;
}

.comments-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.comment-icons {
  width: 25px;
  height: 25px;
}
.comment-title {
  font: normal normal 500 12px/16px Futura Md BT;
  color: #B1B1B1;
}

.influencerDesc{
  color:#B1B1B1 !important;
}

.comment-number-influencers {
  color:#B1B1B1;
  font: normal normal 600 18px/25px Futura Md BT;
}

@media screen and (max-width: 1025px) {
  .step-number-influencers {
    font-size: 150px;
  }
  .step-title {
    font: 14px/24px Futura Hv BT;
    margin-bottom: 0px;
  }
  .step-description {
    top: 114px;
    left: 42px;
  }
  .landing-step-container {
    bottom: 50px;
  }
  .comment-icons {
    width: 20px;
    height: 20px;
  }
  .comment-number-influencers {
    font-size: 13px;
  }
  .comment-title {
    font: normal normal 500 10px/10px Futura Md BT;
  }
  .social-media-icons {
    margin: 10px 0px;
  }
}

@media screen and (max-width:900px) {
  .step-number-influencers {
    font-size: 100px;
  }
  .step-description {
    top: 70px;
    left: 27px;
  }
  .landing-step-container {
    bottom: 50px;
  }
  .social-media-icons {
    margin: 5px 0px;
  }
  .comment-icons {
    width: 15px;
    height: 15px;
  }

  .comment-number-influencers {
    font: normal normal 600 9px/15px Futura Md BT;
  }
}

@media screen and (max-width: 500px){
  .step-number-influencers {
    font-size: 100px;
  }

  .step-description {
    top: 70px;
    left: 27px;
  }

  .step-description-section {
    width: 100%;
  }

  .landing-step-container {
    bottom: 20px;
  }
  .social-media-icons {
    margin: 5px 0px;
  }
  .comment-icons {
    width: 15px;
    height: 15px;
  }

  .comment-number-influencers {
    font: normal normal 600 9px/15px Futura Md BT;
  }
}
