

@media only screen and (max-width: 1300px) {
	.hotelRecapInfo{
		width: 100%  !important
	}
	.hotelRecapCart, .cartItem{
		width: 100%  !important
	}

  }