.reviewContainer {
    /*flex: flex-grow flex-shrink flex-basis|auto|initial|inherit;*/
    flex-grow: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap
}

.reviewContainer .title {
    flex-basis: 100%;
    flex-wrap: nowrap;
    font: 30px/36px Futura Lt BT;
    color: #001A46
}

.reviewContainer .reviews {
    flex-grow: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
}

.reviewContainer .cloud {
    flex-grow: 1;
    flex-basis: 50%;
    flex-shrink: 0;
}

.reviewContainer .user {
    flex-grow: 1;
    flex-basis: 50%;
    flex-shrink: 0;
}