.whyJoin .title {
    margin-top: 100px;
    margin-bottom: 60px;
    text-align: center;
    font: 40px/48px 'Futura Hv BT';
}

.whyJoin{
    margin: 10% 10%;
}

.whyJoin .subtitle{
    margin: 0 20%;
}
