.suggestedDestinationSlider .slick-slide {
  /* padding: 0px 52px 0px 52px; */
  border-radius: 15px;
  margin: 0 10px;
  background: black;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  object-fit: cover !important;

}
.suggestedDestinationSlider .slick-list {
  padding-bottom: 2px;
  height: 200px;
}

@media screen and (min-width: 1200px) {
  .suggestedDestinationSlider .slick-list {
    margin-right: 7%;
    margin-left:7%;
  }
}
.suggestedDestinationSlider {
  max-width: 100vw;
  width: 100%;
}

.suggestedDestinationSlider .slick-track {
  height: 200px;
  
}

.suggestedDestinationSlider .item img {
  border-radius: 15px;
}


.suggestedDestinationSlider  .slick-active {

  
}

.suggestedDestinationSlider img {
  opacity: 0.7;
  max-width: 160px !important;
  max-height: 200px !important;

}

.suggestedDestinationSlider .description {
  position: absolute;
  color: white;
  margin-left: 14px;
  width: 145px;


}

.suggestedDestinationSlider .description h2{
	width: 145px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
