.my_home_faverites_top_btn {
    font: 14px/16px Futura Hv BT;
    border-radius: 25px;
    border: 0;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 0;
    height: 28px;
    display: inline-flex;
}

.my_home_faverites_top_btn_container {
    flex: 1;
    text-align: right;
}

.member_menu_top_btn {
    font: 14px/16px Futura Hv BT;
    border-radius: 25px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 0;
    height: 28px;
    width: 120px;
}

.member_menu_top_btn:active {
    color: #18BC9C;
}
.member_menu_top_btn_places:active {
    color: var(--mainGreen);
}
.member_menu_top_btn_trips:active {
    color: #18BC9C;
}
.member_menu_top_btn_bucketlist:active {
    color: var(--mainOrage);
}


.member_menu_top_btn_active {
    font: 14px/16px Futura Hv BT;
    border-radius: 25px;
    background-color: var(--mainGreen);
    color: var(--mainWhite);
    margin-right: 5px;
    margin-bottom: 5px;
    border: 0;
    height: 28px;
    width: 120px;
}

.member_menu {
    background-color: #E8E8E8;
    color: #191919CC;
}

.home_favorites_icon {
    width: 15px;
    height: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-right: 5px;
}


.text_title {
    text-align: left;
    font: 16px/18px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainGreen);
    opacity: 1;
}

.text_content {
    text-align: left;
    font: 16px/18px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainWhite);
    opacity: 1;
}

/* mobile size */
@media screen and (max-width: 768px) {
    .member_menu_top_btn {
        font: 12px/16px Futura Hv BT;
        border-radius: 25px;
        margin-right: 5px;
        margin-bottom: 5px;
        border: 0;
        height: 28px;
        width: auto;
    }

    .member_menu_top_btn_active {
        font: 14px/16px Futura Hv BT;
        border-radius: 25px;
        background-color: var(--mainGreen);
        color: var(--mainWhite);
        margin-right: 5px;
        margin-bottom: 5px;
        border: 0;
        height: 28px;
        width: auto;
        min-width: 70px;
    }
    

}