.timelinePageDate .MuiInputBase-input{
    width: 100% !important;
}


.timelinePageTop{
    position: sticky;
    top: 0px;
    background: white;
    z-index: 10;
    padding-top: 10px;
}

@media screen and (max-width: 766px) {
    .timelineEllipsis{
       z-index: 1000 ;
       top: 5px;
       position: absolute;
       right: 10px;
    }
} 
