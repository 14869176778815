.mobileBucketlist{
    margin-top: 30px;
    margin-left: 0px;
}

.mobileBucketlist .react-horizontal-scrolling-menu--scroll-container {
   
}

.mobileBucketlist  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    background: transparent  !important; /* make scrollbar transparent */
    width: 0px  !important;
    display:none !important;
}