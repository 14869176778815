.termsBody h3 {
    font: 24px/24px Futura Hv BT;
}

.termsBody {
    color: black;
}
.termsBody p {
    font: 14px/14px Futura LT BT;
    color:  #202020;
    line-height: 25px;
}