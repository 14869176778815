.about_title_value {
    text-align: left;
    font: 24px/29px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainGreen);
    opacity: 1;
    margin-left: 10px;
}

.about_title_container {
    display: flex;
    display: -webkit-flex;
}

.about_title {
    text-align: left;
    font: 24px/26px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainWhite);
    opacity: 1;
}

.about_desc {
    text-align: left;
    font: 14px/25px Futura Lt BT;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}


.see_more_about_container {
    cursor: pointer;
}

