.areYouMuver .muvers-heading {
    color: #19BC9B;
    margin-bottom: 70px;
    text-align: center;
    width: 100%;
    font: 24px/40px 'Futura Hv BT';
}


.inspired-link {
    margin: 0px 75px 65px;
    color: #19BC9B;
    cursor: pointer;
    font-family: 'Futura Lt BT';
    text-align: right;
}

@media only screen and (max-width:600px) {
    .areYouMuver h2.muvers-heading {
        margin-bottom: 0px;
    }
}