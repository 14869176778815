

/* //ipad */
@media only screen and (max-width: 1400px) {
	.hotelPaymentForm{
		width: 100%  !important
	}
	.hotelPaymentCart{
		width: 100%  !important
	}

  }

  @media only screen and (max-width: 900px) {
	.hotelPaymentForm{
		/* margin-top: 40px !important; */
	}

  }