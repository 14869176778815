.upcoming_trip_style {
    background: rgba(25, 188, 155, 0.2) 0% 0% no-repeat padding-box;
    border-color: rgba(25, 188, 155, 0.2) !important;
}

.ui.rating .icon {
    color: #10734c73 !important;
}

.ui.rating .active.icon {
    color: var(--mainGreen) !important
}


/* vertical line end  */



.btn_rounded {
    border-radius: 20px !important;
    vertical-align: middle;
    
    user-select: none;
    display: inline-block;
    padding: .25rem .5rem;
}
