.testimonial .title {
    margin-top: 100px;
    margin-bottom: 60px;
    text-align: center;
    font: 40px/48px 'Futura Hv BT';
}

.testimonial .border {
    padding: 25px;
    margin-top: 43px;
    padding-top: 30px;
    height: 215px;
    position: relative;
}

.testimonial .testimonial-avatar {
    width: 75px !important;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
}


.testimonial .text {
    color: grey;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 5px;
    font-family: Futura Lt BT;
}

.testimonial .slick-center {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
}

.testimonial .slick-active {

    padding: 38px;
}

.testimonial .date {
    font-size: 15px;
    margin-top: 30px;
    font-family: Futura Md BT;
}

.testimonial .col-md-4 {
    max-width: 30% !important;
    flex: 0 0 30%;
}


.testimonial .col-md-4.center-box {
    max-width: 40% !important;
    flex: 0 0 40%;
    margin-top: -20px !important;
}

.increaseHeight {
    height: 260px !important;
}

.testimonial div.btn.btn-primary.ExploreMore {
    margin-top: 90px;
    margin-bottom: 60px;
}

.testimonial .avatar_fullname {
    text-align: center;
}


@media only screen and (max-width:900px) {

    .testimonial .col-md-4,
    section.testimonial .col-md-4.center-box {
        padding-bottom: 30px;
        max-width: 100% !important;
        flex: 0 0 100%;
    }
}

@media only screen and (max-width: 768px) {
    .testimonial .title {
        margin-top: 50px;
        font: 18px/48px 'Futura Hv BT';
        margin-bottom: 30px;
    }

    .testimonial div.btn.btn-primary.ExploreMore {
        margin-top: 210px;
        margin-bottom: 60px;
    }
}