.btn.btn-primary.ExploreMore {
    background: #19BC9B !important;
    border-color: lightseagreen;
    color: white;
    float: none;
    font-weight: 400;
    height: 66px;
    width: 200px;
    padding: 15px 15px;
    margin-top: 15px;
    border-radius: 0;
    text-align: center;
    font: 18px/18px 'Futura Md BT';
}

.bucketListSlider .slick-slide {
    width: 303px !important;
    transition: transform .6s; /* Animation */
  }





.bucketListSlider .slick-track {
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
  }


.bucketListSlider  {
    width: 87vw;
    height: 600px;
  }

.bucketListSlider .slick-slide:hover {
    transform: scale(1.15);
  }
  
.header_homepage {
    animation-duration: 3s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}


.content-section h2 {
    color: white;
    font-size: 36px;
    font-family: 'Futura Hv BT';
}

.mobile .content-section h2 {
    text-align: left;
    font: 18px/18px 'Futura Hv BT';
    color: white;
}

.mobile .content-section p {
    text-align: left;
    font-family: 'Futura Md BT';
    color: white;
}

.content-section p {
    color: white;
    font-size: 18px;
    font-family: 'Futura Md BT';
}

.content-section button {
    background: #19BC9B !important;
    color: aliceblue;
    border-radius: 0px;
    border: 0;
    height: 65px;
    width: 200px;
    margin-top: 15px;
    border-radius: 0;
    font: 18px/18px 'Futura Md BT';
}


.background-img-row .tabSelection h4 {
    line-height: 20px;
    width: 100%;
    margin-top: 9px;
    margin-bottom: 3px;
    font-weight: initial !important;
    text-align: center;
    font: 18px/28px 'Futura LT BT';
}

.background-img-row .tabSelection .tabs-manual {
    margin-right: 10px;
    cursor: pointer;
    border-bottom: 6px solid;
    flex: initial;
    width: 120px;
}

.activeTab {
    color: #19BC9B;
}


.background-img-row .form-control {
    height: 60px;
    padding-left: 30px;
    font: 18px/30px "Futura LT BT";
    color: #202020;
}


.background-img-row , select {
    font: 18px/30px "Futura LT BT" !important;
    color: #202020;
}
.background-img-row .form-control,  input{
    font: 18px/30px "Futura LT BT";
    color: #202020;
    /* text-transform: capitalize; */
}


.background-img-row .form-control:focus {
    box-shadow: none;
}

.background-img-row select {
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 88%;
    width: 100%;
}

.background-img-row .submitBtn {
    background: #19bc9b;
    outline: 0;
    border-radius: 0;
    color: white !important;
    height: 60px;
    width: 100%;
    font: 18px/28px 'Futura Md BT';
}

.background-img-row .iconInInput {
    position: absolute;
    left: 10px;
    top: 12px;
    color: #000;
}

.background-img-row .featuredBlock {
    color: black;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    background: #fff;
    height: 215px;
    font-family: 'Futura Lt BT';
}

.background-img-row .featuredSection{
    position: absolute;
    top: 310px;
    left: 0px;
}

.background-img-row .featured-TextBlock {
    padding: 32px 0px;
    box-sizing: border-box;
    padding-left: 23px;
}

.background-img-row .featuredPic {
    float: right;
    text-align: right;
}


.background-img-row .discover-landscape {
    font-size: 25px;
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    color: #000;
    font-family: 'Futura Lt BT';
}

h5.dicover-landscape {
    color: black;
    font-size: 25px;
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Futura Hv BT';
    width: 245px;
}

h6.dicover-landscape a {
    text-decoration: none;
    color: black;
}

.overlay {
    background: rgba(25, 25, 25, 0.2);
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
}

.background-img-row .text-icon {
    margin-left: -2px;
}


.savedItem {
    width: 20px !important;
    position: absolute;
    top: 15px;
    right: 15px;
}



/*bootstrap carousel style*/
.background-img-row .carousel-control-prev-icon,
.carousel-control-next-icon {
    background: none;
}

.background-img-row .carousel-control-prev-icon:before {
    content: 'Previous';
    border-bottom: 7px solid #19BC9B;
    overflow: hidden;
    color: #fff !important;
    padding-bottom: 10px;
    margin-left: -70px;
    padding-left: 100px;
    font: 18px/24px 'Futura Lt BT';
}

.carousel-control-next,
.carousel-control-prev {
    width: 8.5% !important;

}

.background-img-row .carousel-control-next-icon:before {
    content: 'Next';
    border-bottom: 7px solid #19BC9B;
    margin-left: -70px;
    padding-right: 162px;
    overflow: hidden;
    color: #fff !important;
    padding-bottom: 10px;
    font: 18px/24px 'Futura Lt BT';
}

.background-img-row .carousel-control-prev,
.carousel-control-next {
    opacity: 1;
}

.background-img-row .carousel-caption {
    top: 100px;
    padding-top: 0px;
}

.background-img-row .react-datepicker-wrapper {
    width: 100%;
}


/* radi0-btn */

.radio-inline {
    position: relative;
    padding-left: 34px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font: 18px/28px 'Futura LT BT';
}

/* Hide the browser's default radio button */
.radio-inline input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.background-img-row .checkmark {
    position: absolute;
    top: 3px;
    left: 9px;
    height: 18px;
    width: 18px;
    background-color: #eeeeee00;
    border-radius: 50%;
    border: solid 2px #19bc9b;
}

/* On mouse-over, add a grey background color */
.radio-inline:hover input~.checkmark {
    background-color: #19bc9b50;
}

/* When the radio button is checked, add a blue background */
.radio-inline input:checked~.checkmark {
    background-color: #eeeeee00;
    border: 2px solid #19bc9b;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-inline input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-inline .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #19bc9b;
}

label.radio-inline.radio-2 {
    margin-left: 20px;
}

label.radio-inline.radio-3 {
    margin-left: 20px;
}




.green {
    color: #19BC9B;
}

.background-img-row .addDestinationBtn {
    color: #19BC9B;
    border: dashed 1px;
    height: 60px;
    border-radius: 0;
    font: 18px/30px "Futura Md BT";
}

.background-img-row .closeIcon {
    position: absolute;
    right: -15px;
    cursor: pointer;
}

.showOptions {
    height: 50px;
    color: #19bc9b;
    font: 18px/50px 'Futura Hv BT';
}

.pl-0-resp-initial {
    padding-left: 0px;
}

.divisional-line {
    width: 90%
}

.booking_form_container .dropdown-item,
.booking_form_container input {
    font: 18px/30px "Futura LT BT";
    color: #202020;
}

.homePage{
    max-width: 100vw;
    overflow: hidden;
}

@media only screen and (max-width: 700px) {
	.booking_form_container {
        padding: 15px;
        color: #202020 !important;
    }

    .booking_form_container .position-absolute {
      top:3px !important;
    }

    .booking_form_container  .form-control, .react-datepicker__input-container{
        font: 13px  "Futura Md BT" !important;
    }
    .booking_form_container ::placeholder{
        color: #202020 !important;
    }

    .flightSubmitBtn{
        display: block;
        min-width: 100%;
    }

    .flightSubmitBtn button{
        min-width: 86vw !important;
    }

    .suggestedDestination .header {
        padding-left: 20px;
    }
    .suggestedDestination .searcher{
        display: none;
    }

    .suggestedDestination .destinationSlider{
        margin-top: 20px;
        width: 105vw;
        margin-left: 0px;
        max-width: 103vw;
    }

    .bucketListSlider{
        width: 92vw !important;
        padding-bottom: 0px !important;
        height: auto !important;
    }

    .bucketListSlider .slick-track{
        padding-bottom: 0px !important;
    }

    .bucketListSlider .slick-slide{
        margin-left: -20px;
        margin-right: -20px;
    } 

    .discoverDestinations{
        max-width: 100%;
    overflow: overlay;
   }

    .discoverDestinations div{
        font-size: 15px !important;
    }
    .homePage{
        max-width: 100vw;
        overflow: hidden;
    }

    .homePage    .headerMobile {
        position: absolute !important;
        z-index: 4 !important;
        width: fit-content;
    }

    .radio-inline{
        color: inherit;
    }

  }
/*.background-img-row .dropdown-menu.show {
    width:430px !important;
}*/

@media only screen and (max-width:800px) {

    .background-img-row .carousel-control-next-icon:before {
        padding-right: 80px;
    }

    .background-img-row .form-control,  input{
        font: 12px/30px "Futura LT BT" !important ;
        color: #202020;
        /* text-transform: capitalize; */
    }
    
}

@media only screen and (max-width:1366px) {

    .background-img-row .carousel-control-next-icon:before {
        padding-right: 80px;
    }

    .background-img-row .form-control  input{
        font: 14px/30px "Futura LT BT" !important ;
        color: #202020;
        /* text-transform: capitalize; */
    }
    
}


@media only screen and (min-width: 1200px) {

    
    .section_home .container {
        padding-left: 8.5%;
        padding-right: 8.5%;
        max-width: 100%;
    }

    .background-img-row .container {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 100%;
    }

    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
    }
}

@media only screen and (max-width:1025px) {

    .background-img-row .carousel-control-next-icon:before {
        padding-right: 60px;
    }
}

@media only screen and (min-width: 992px) {

    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
    }

    .section_home .container {
        padding-left: 8.5%;
        padding-right: 8.5%;
        max-width: 100%;
    }

    .background-img-row .container {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 100%;
    }

}

@media only screen and (max-width:900px) {

    .showOptions {
        height: 40px;
        color: #19bc9b;
        font: 10px/40px 'Futura Hv BT';
    }

    .btn.btn-primary.ExploreMore {
        font: 10px/18px 'Futura Md BT';
    }

    .background-img-row .checkmark {
        top: -3px;
    }

    label.radio-inline.radio-2 {
        margin-left: 5px;
    }

    label.radio-inline.radio-3 {
        margin-left: 5px;
    }

    .radio-inline {
        padding-left: 30px;
        margin-bottom: 4px;
        font: 11px/13px 'Futura Md BT';
    }

    .background-img-row .form-control {
        height: 40px;
        font: 11px/13px "Futura Md BT";
    }
    

    .background-img-row .submitBtn {
        width: 100%;

        height: 40px;
        font: 11px/26px 'Futura Md BT';
    }

    .savedItem {
        right: 40px
    }

    .booking_form_container .dropdown-item,
    .booking_form_container input {
        font: 11px/13px "Futura Md BT";
    }

    .background-img-row .addDestinationBtn {
        height: 40px;
        font: 11px/13px "Futura Md BT";
    }

    .background-img-row .tabSelection h4 {
        font: 10px/12px 'Futura Md BT';
        text-align: center;
    }

    .background-img-row .carousel-caption {
        /*position: initial;*/
        padding-top: 25px;
    }

    .background-img-row .carousel-control-next-icon {
        display: none;
    }

    .background-img-row .carousel-control-prev-icon {
        display: none;
    }

    .background-img-row .carousel-caption {
        position: initial;
        background: linear-gradient(rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)),
            /* bottom, image */
            url('https://amhqajbken.cloudimg.io/v7/https://storage.googleapis.com/muvimages/home_slider_images/Angkor.jpg');
        padding: 10px;
        padding-top: 90px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .background-img-row .carousel-inner {
        display: none;
    }

}


@media only screen and (max-width: 768px) {

    .section_home {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .section_home.suggestedDestination .col-md-10 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .homePage .col,
    .homePage .col-1,
    .homePage .col-10,
    .homePage .col-11,
    .homePage .col-12,
    .homePage .col-2,
    .homePage .col-3,
    .homePage .col-4,
    .homePage .col-5,
    .homePage .col-6,
    .homePage .col-7,
    .homePage.col-8,
    .homePage .col-9,
    .homePage .col-auto,
    .homePage .col-lg,
    .homePage .col-lg-1,
    .homePage .col-lg-10,
    .homePage .col-lg-11,
    .homePage .col-lg-12,
    .homePage .col-lg-2,
    .homePage .col-lg-3,
    .homePage .col-lg-4,
    .homePage .col-lg-5,
    .homePage .col-lg-6,
    .homePage .col-lg-7,
    .homePage .col-lg-8,
    .homePage .col-lg-9,
    .homePage .col-lg-auto,
    .homePage .col-md,
    .homePage .col-md-1,
    .homePage .col-md-10,
    .homePage .col-md-11,
    .homePage .col-md-12,
    .homePage .col-md-2,
    .homePage .col-md-3,
    .homePage .col-md-4,
    .homePage .col-md-5,
    .homePage .col-md-6,
    .homePage .col-md-7,
    .homePage .col-md-8,
    .homePage .col-md-9,
    .homePage .col-md-auto,
    .homePage .col-sm,
    .homePage .col-sm-1,
    .homePage .col-sm-10,
    .homePage .col-sm-11,
    .homePage .col-sm-12,
    .homePage .col-sm-2,
    .homePage .col-sm-3,
    .homePage .col-sm-4,
    .homePage .col-sm-5,
    .homePage .col-sm-6,
    .homePage .col-sm-7,
    .homePage .col-sm-8,
    .homePage .col-sm-9,
    .homePage .col-sm-auto,
    .homePage .col-xl,
    .homePage .col-xl-1,
    .homePage .col-xl-10,
    .homePage .col-xl-11,
    .homePage .col-xl-12,
    .homePage .col-xl-2,
    .homePage .col-xl-3,
    .homePage .col-xl-4,
    .homePage .col-xl-5,
    .homePage .col-xl-6,
    .homePage .col-xl-7,
    .homePage .col-xl-8,
    .homePage .col-xl-9,
    .homePage .col-xl-auto {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media only screen and (max-width:600px) {

    .homePage .booking_form_container .radio-inline {
      color: white;
    }

    .background-img-row .carousel-caption {
        position: initial;
        background: linear-gradient(rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)),
            /* bottom, image */
            url('https://amhqajbken.cloudimg.io/v7/https://storage.googleapis.com/muvimages/home_slider_images/Angkor.jpg');
        padding: 10px;
        padding-top: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .background-img-row .carousel-inner {
        display: none;
    }

    .background-img-row .tabSelection .tabs-manual {
        border-bottom: 2px solid;
        padding-bottom: 10px !important;
    }

    .pl-0-resp-initial {
        padding-left: 15px;
    }
}