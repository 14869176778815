.activitiesForm input {
    font: 14px/30px "Futura LT BT" !important;
    color: #202020;
}

.activitiesForm  select {
    font: 14px/14px "Futura LT BT" !important;
    color: #202020;
}

.activitiesForm  .duration {
    font: 14px/14px "Futura LT BT" !important;
    color: #202020 !important;
}

.activitiesForm input[type="time"]::-webkit-calendar-picker-indicator {
    /* background: none; */
    font-size: 17px;
    margin-right: 5px;
    color: rgb(112, 112, 112) !important;
}