.muverTripPage .submitBtn {
    background: #19bc9b;
    outline: 0;
    border-radius: 0;
    color: white !important;
    height: 60px;
    width: 100%;
    font: 18px/28px 'Futura Md BT';
}


.uploadBookingArea::placeholder {
    text-align: center;
    padding-top: 50px;
    font: 35px/28px 'Futura Md BT';
    color:lightgray

}