.suggestedDestination {
    flex-direction: column;
    display: flex;
}

.suggestedDestination .header {
    display: flex;
    flex-direction: row;
    /*top right bottom left */
    margin: 0px 0px 40px 0px;
    justify-content: space-between;
    align-items: center;;
}

.suggestedDestination img {
    opacity: 0.9;
    max-width: 160px !important;
    max-height: 200px !important;
  
  }


.suggestedDestination .title {
    width: auto;
    text-align: left;
}

.suggestedDestination .searcher {
    width: 400px;
    text-align: right;
}

.suggestedDestination .slider{
    flex-grow: 1;
    flex-direction: row;
}

.suggestedDestination a h2 {
    color: "var(--frameBlack)";
}

.suggestedDestination .item h2 {
    height: auto;
    text-align: center;
}


@media only screen and (max-width:900px) {
    .suggestedDestination .header {
        margin: 5px 5px 20px 5px;
    }
}

.destinationSlider .slick-slide {
    padding: 0 22px;
  }
  .destinationSlider .slick-list {
    margin-right: 8%;
    padding-bottom: 2px;
  }  
.destinationSlider{
    margin-top: 88px;
    margin-left: -33px;
    max-width: 100vw;
    width: 100%;
}

.mobileSuggested{
    margin-top: 30px;
    margin-left: 20px;
}

.mobileSuggested .react-horizontal-scrolling-menu--scroll-container {
   
}

.mobileSuggested  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    background: transparent  !important; /* make scrollbar transparent */
    width: 0px  !important;
    display:none !important;
}