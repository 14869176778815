.muvInPress div.carousel-heading-2 {
    margin-top: 180px;
    font-weight: 700;
    font-size: 40px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 60px;
    font-family: 'Futura Hv BT';
    color: #202020
}
@media only screen and (max-width: 768px) {
    
    .muvInPress div.carousel-heading-2 {
        font-size:24px;    
        margin-top: 0px;
        margin-bottom: 25px;
    }
    .muvInPress .divisional-line { margin-top: 80px; }
    .muvTravelBlog div.carousel-heading-2 {
        font-size:24px;   
    }
}