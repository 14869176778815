
.destinations_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  margin: 0;
}
.destinations_days_container {
  position: absolute;
  top: 10%;
  left: 88%;
  z-index: 1;
  width: 100%;
  margin: 0;
}
.destinations_days_container_day {
  position: absolute;
  top: 10%;
  left: 88%;
  z-index: 1;
  width: 100%;
  margin: 0;
}
.destinations_days_container_dialog {
  position: absolute;
  top: 10%;
  left: 88%;
  z-index: 1;
  width: 100%;
  margin: 0;
}
.destinations_days_container_dialog_day {
  position: absolute;
  top: 10%;
  left: 88%;
  z-index: 1;
  width: 100%;
  margin: 0;
}

.bg_color_map_detination_btn {
  border: 1px solid #E8E8E8;
  background: var(--mainWhite) 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: var(--mainGreen);
  padding: 1px;
}
.bg_color_map_days_btn {
  border: 1px solid #E8E8E8;
  background: var(--mainWhite) 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: var(--mainGreen);
  padding: 1px;
  width: 110px;
}
.bg_color_map_days_btn_day {
  border: 1px solid #E8E8E8;
  background: var(--mainWhite) 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: var(--mainGreen);
  padding: 1px;
  width: 90px;
}
.destination_active {
  background: var(--mainGreen) 0% 0% no-repeat padding-box !important;
  color: var(--mainWhite) !important;
}