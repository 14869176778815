.card-section .card {
    border: none !important;
}

.card-img,
.card-img-top {
    border-radius: 0px;
}

.rating_home {
    font-size: 15px;
    color: #19BC9B;
    font-weight: 700;
}

.card-section .card-body {
    padding-left: 0px;
    padding-right: 0px;
}

.card-section .card-tittle {
    font-weight: 900;
    max-width: 299px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 22px;
    color: #000;
    overflow: hidden;
    font: 18px / 20px Futura Md BT;
}

p.card-text {
    color: #b1b1b1;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
    text-align: left;
    height: 70px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
}

section.card-section .card-body a {
    color: #19BC9B;
    font-size: 14px;
    font-weight: 100;
    width: 100%;
    position: absolute;
    text-align: right;
    bottom: 30px;
}

.text-icon {
    margin-left: 10px;
}

.card-section h6.card-subtitle.mb-2.text-muted {
    color: #19BC9B !important;
    /* font-weight: 700; */
    text-align: left;
}

h5.card-title-1 {
    font-size: 34px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: bold;
    text-align: left;
}

@media only screen and (max-width:900px) {
    .card-section .card {
        height: auto;
    }

    .card-section .card-body {
        height: 75px;
    }

    .card-section .card-tittle {
        margin-bottom: 5px;
        text-align: left;
        font-size: 15px;
        color: var(--bgBlack);

    }
}