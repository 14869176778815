.topBarEstablishment img {
  -webkit-transition: all 1.1s;
  -moz-transition: all 1.1s;
  -ms-transition: all 1.1s;
  -o-transition: all 1.1s;
  transition: all 1.1s;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .MuiBreadcrumbs-li {
    font-size: 11px;
  }

  .MuiBreadcrumbs-ol {
    /* margin-bottom: 30px !important; */
  }

  .viewGalleryEst {
    /* display: none !important; */
  
  }
  .establishmentTitle {
    font-size: 14px !important;
    font-weight: 100 !important;
    margin-top: -20px;
  }

  .establishmentName {
    margin-top: -60px;
  }

  .addPhotosEst {
    right: -40px;
    top: -20px;
    position: absolute;
  }

  .starEst {
    display: none !important;
  }
  .establishmentMenu {
    display: none !important;
  }

  .establishmentContainer {
    padding: 20px;
  }
  .reviewsUserPost .textField {
    width: 88vw !important;
  }

  .reviewContainer .reviews {
    display: block !important;
  }

  .reviewContainer .box_3 {
    width: 90vw !important;
  }

  .establishmentPost {
    display: block !important;
    margin-bottom: 50px;
    max-width: 90vw !important;
  }
}
