.reviewsUserRating {
    flex-grow: 1;
    flex-basis: 30%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap
}

.reviewsUserRating .header {
    flex-grow: 1; 
    margin-top: 45px;
}

.reviewsUserRating .barScale {
    flex-grow: 1;
    flex-shrink: 0;
    height: 300px;
    width: 350px;
    margin-top: 60px;
}

