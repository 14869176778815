.reviewsUserPost {
    display: flex;
    flex-direction: column;
}

.reviewsUserPost .label {
    height: 30px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: bold;
    color: #274674;
    font: 18px/14px Futura Lt BT;
}

.reviewsUserPost .input {
    flex-grow: 1;
}

.reviewsUserPost .textField {
    padding-Left: 2px;
    font-size: 14px;
    width: 400px;
    height: 200px,
}

.reviewsUserPost .submitButton
{
    font: 16px/16px Futura Md BT;
    color: var(--mainWhite);
    text-transform: none;
    height: 40px;
    width: 150px;
}

