.hotelDates .react-datepicker-popper {
    z-index: 4
}



.hideBorder input {
    border: none !important;
}

@media only screen and (min-width: 800px) {
    .flightRoundTripForm .toAirport input {
        /* border:none; */
        /* border-top-left-radius: 0;
        border-bottom-left-radius: 0; */
        padding-left: 45px;
    }

    .flightRoundTripForm .toAirport .position-absolute {
        position: absolute;
        left: 20px !important;
    }


    .flightRoundTripForm .fromAirport input {
        /* border:none; */
        /* border-top-right-radius: 0;
        border-bottom-right-radius: 0; */
        padding-right: 50px;
    }

    .flightRoundTripForm .fromAirport .rbt-aux {
        position: absolute;
        right: 20px
    }

    .flightRoundTripForm .switchButton {
        position: absolute;
        z-index: 10;
        left: -20px !important;
        background: white;
        border: 1px solid #202020

    }

    .flightRoundTripForm .switchButton:hover {
  
    }


}





.flightRoundTripForm .react-datepicker {
    display: flex;
    /* left: 0px !important; */
    position: absolute;
    z-index: 100;
}


@media only screen and (min-width: 1200px) {

    .embeddedDates.flightRoundTripForm .react-datepicker {
        right: -200px !important;
        position: absolute;
        z-index: 100;
        top:100px
    }

    .embeddedDates.flightRoundTripForm .dateTo .react-datepicker  {
        right: -10px !important;
        position: absolute;
        z-index: 100;
        top:100px
    }
}

.embeddedDates.flightRoundTripForm .react-datepicker__day {
    /* padding: 5px !important; 
    /* height: 3.6vw; */
    width: 3.6vw !important;
    min-height: 30px;
    min-width: 30px;
    border: 1px solid lightgray;
    border-radius: 10px;
 
}

/* .embeddedDates.flightRoundTripForm .react-datepicker__day--keyboard-selected, .react-datepicker__day--in-range {
background: var(--mainDark) !important;
} */




/* @media only screen and (max-width: 1200px) {
    .embeddedDates.flightRoundTripForm .react-datepicker__day {

        text-align: center;
    }
    
} */


@media only screen and (max-width: 800px) {
    .flightRoundTripForm .switchButton {
        display: none;
    }
    
}


.embeddedDates.flightRoundTripForm .react-datepicker__day-name {


    width: 3.6vw !important;

    min-width: 30px;

} 