.past_trip_all {
    flex: 1;
    text-align: right;
}

.past_trip_container {
    display: flex !important;
    display: -webkit-flex !important;
}


.schedulePlane{
    background-image: linear-gradient(to bottom, #AAA 1px, transparent 1px), linear-gradient(to right, #AAA 1px, transparent 1px), radial-gradient(1px 1px at center, #AAA 1px, transparent 2px), radial-gradient(1px 1px at center, #AAA 1px, transparent 2px);
    background-size: 40px 60px, 120px 40px, 6px 60px, 6px 60px;
    background-position: 0px 0px, 0px 0px, 0px -10px, 0px 10px;
}

.flightLine{
    position: absolute;
    top: 47%;
    width: 90%;
    z-index: 1;
        height: 1px;
        background-image: linear-gradient(90deg, #E8E8E8, #E8E8E8 75%, transparent 75%, transparent 100%);
        background-size: 10px 1px;
}


.muverFlightItem img{
    background: white;
    z-index: 3;
    position: relative;
}

.muverFlightItem .imgTransparent img{
    background: transparent !important;
    z-index: 3;
    position: relative;
}

.onTopLine {
    background: white;
    z-index: 3;
    position: relative;
}