
.steps_timeline {
    padding-left: 30px;
}

.steps_timeline .step {
    position: relative;
}

.steps_timeline .step:before {
    content: '';
    display: block;
    position: absolute;
    left: 50px;
    width: 3px;
    top: 0;
    height: 100%;
    background: var(--mainGreen);
}


.steps_timeline .step .title:before {
    content: '';
    display: block;
    position: absolute;
    left: 60px;
    width: 12px;
    top: 5px;
    height: 12px;
    border-radius: 50%;
    background: var(--mainGreen);
}

.steps_timeline .step .body {
    padding-bottom: 30px;
}

