.profile_section_common .profile_btn_invite {
  width: 230px;
  height: 40px;
  margin: 2.5px 5px 2.5px 5px;
  font: 16px/19px Futura Hv BT;
  font-weight: bold;
  letter-spacing: 0;
  opacity: 1;
}

@media screen and (min-width: 1200px) {

  .profile_section_common .number-content {
    margin-top: 1.72px;
  }

  .profile_section_common .profile-btn {
    width: 140px;
    height: 40px;
    margin: 2.5px 5px 2.5px 5px;
    font: 16px/19px Futura Hv BT;
    font-weight: bold;
    letter-spacing: 0;
    opacity: 1;
  }



  .profile_section_common .avatar_banner {
    height: 60px;
    text-align: center;
    font: 16px/21px Futura Md bt;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .avatar_followers {
    text-align: center;
    font: 24px/29px Futura Md bt;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
  }

  .profile_section_common .avatar_followers_text {
    text-align: center;
    font: 14px/16px Futura Md bt;
    letter-spacing: 0;
    color: #B1B1B1;
    opacity: 1;
  }


  .profile_section_common .avatar_fullname {
    text-align: center;
    font: 24px/29px Futura Md bt;
    font-weight: bold;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .avatar_username {
    text-align: center;
    font: 16px/21px Futura Hv BT;
    letter-spacing: 0;
    color: #19BC9B;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .profile_photo {
    width: 180px;
    min-height: 180px;
    object-fit: cover;

  }

}


.profile_section_common .number-content {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-around;
}


.avatar_btn_container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
}



.btn-outline-success {
  border-color: var(--mainGreen);
  color: var(--mainGreen);
}

.btn-outline-success:hover {
  background-color: var(--mainGreen);
  color: var(--mainWhite);
}

.btn-outline-success:active {
  background-color: var(--mainGreen) !important;
  color: var(--mainWhite) !important;
}



@media screen and (max-width: 1200px) {
  .profile_section_common {
    position: fixed;
    width: 290px !important;
    height: 507.5px !important;
  }

  .profile_section_common .profile-btn {
    width: 110px;
    height: 35px;
    margin: 2.5px 5px 2.5px 5px;
    font: 10px/19px Futura Hv BT;
    font-weight: bold;
    letter-spacing: 0;
    opacity: 1;
  }

  .profile_section_common .avatar_followers {
    text-align: center;
    font: 20px/29px Futura Md bt;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
  }

  .profile_section_common .avatar_followers_text {
    text-align: center;
    font: 12px/16px Futura Md bt;
    letter-spacing: 0;
    color: #B1B1B1;
    opacity: 1;
  }


  .profile_section_common .avatar_fullname {
    text-align: center;
    font: 20px/29px Futura Md bt;
    font-weight: bold;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .avatar_username {
    text-align: center;
    font: 12px/21px Futura Hv BT;
    letter-spacing: 0;
    color: #19BC9B;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .profile_photo {
    width: 120px;
    min-height: 120px;
    object-fit: cover;

  }

  .profile_section_common .number-content {
    margin-top: 1.13px;
  }

  .profile_section_common .avatar_banner {
    height: 60px;
    text-align: center;
    font: 16px/21px Futura Md bt;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

}


@media screen and (max-width:768px) {
  .profile_section_common {
    position: relative !important;
    display: block !important;
    width: auto !important;
    margin-left: 0 !important;
    top: 120px !important;
  }

  .profile_section_common .profile-btn {
    width: 115px;
    height: 40px;
    margin: 2.5px 5px 2.5px 5px;
    font: 10px/19px Futura Hv BT;
    font-weight: bold;
    letter-spacing: 0;
    opacity: 1;
  }

  .profile_section_common .avatar_followers {
    text-align: center;
    font: 24px/29px Futura Md bt;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
  }

  .profile_section_common .avatar_followers_text {
    text-align: center;
    font: 14px/16px Futura Md bt;
    letter-spacing: 0;
    color: #B1B1B1;
    opacity: 1;
  }


  .profile_section_common .avatar_fullname {
    text-align: center;
    font: 20px/29px Futura Md bt;
    font-weight: bold;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .avatar_username {
    text-align: center;
    font: 16px/21px Futura Hv BT;
    letter-spacing: 0;
    color: #19BC9B;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .profile_section_common .profile_photo {
    width: 180px;
    min-height: 180px;
    object-fit: cover;

  }

  .profile_section_common .avatar_banner {
    height: 60px;
    text-align: center;
    font: 16px/21px Futura Md bt;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  .futura_md_bt_16_22_white {
    text-align: left;
    font: 8px/22px Futura Md BT;
    letter-spacing: 0;
    color: var(--mainWhite);
  }
}