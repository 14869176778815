

@media only screen and (max-width: 700px) {
	.featurePageBody{
        background: #191919;
        color:white  !important
    }
}
@media only screen and (max-width: 700px) {


    .featurePageBody .MuiFab-root{
        /* display: none; */
    }

    .featureDisplay{
        display: none !important;
    }
    .featurePageBody .menu{
        padding-bottom: 20px;
        background: inherit !important;
      
    }
    .featurePageBody  .featureTypeName{
     font-size: 10px !important ;
     line-height: 10px !important;
     color: white !important;
    }

    .featureType  svg{
        height: 10px !important ;
        stroke: white;
    }

    .featureType{
        padding: 8px 10px !important;
        margin-bottom: 6px;
        background: inherit !important;
    }

    .featureTypes{
        margin: 10px;
    }
    .featureActions{
    text-align: right;
    position: absolute;
    right: 0px;
    top: 270px;
    right: -40px;

    }

    .featureActions a {
        color: white;
    }

    .destinationImageActions{
        right: 10px  !important
    }

     .features p {
        min-width: 80px;
        text-align: center;
        margin-left: -13px;
        color: white !important;
    }

    .rankingSelect{
        width: 100% !important;
    }

    .draggable{
        padding-bottom: 20px;
    }

    .topBarDestination .box_1{
        /* margin-top: -35px; */
        padding-left: 20% !important;
    }
    .topBarDestination{
        /* padding-top: 30px; */
    }

    .establishmentBody {
        background-color: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        width: 94% !important;
        margin-right: 10px;
        margin-left: 10px;
    }

    .establishmentImageContainer {
        background-color: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 94.2% !important;
        margin-right: 10px;
        margin-left: 10px;

    }

    .establishmentImage{
        width: 100% !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-right: 10px;
        margin-left: 0px;
    }
    .bucketListName{
        color: white !important;
    }

    .aboutEstablishment div{
        color: white !important;
        padding-left: 10px;
        padding-right: 10px;
    }

   
    .tripsBody {
        background-color: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        width: 94% !important;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
    }

    .tripsImage{
        width: 94% !important;
        margin-right: 10px;
        margin-left: 10px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .stickyHeaderDestination{
        background: inherit !important;
    }

    .headerMobile {
        position: relative !important;
        z-index: 4 !important;
        width: fit-content;
    }

    .noResults {
        color: white !important;
    }
}


.featureDisplay,.featureActions{
    display: flex;
}

.featureActions{
    margin-right:180px;
}