.photoUpload {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 6px #00000029;
  width: 863px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.photoBorder {
    width: 810px;
    border-radius: 19px;
    margin: 0 auto;
    margin-top: 82px;
    margin-bottom: 30px;
}


.photoBody {
    position: absolute;
    width: 360px;
    text-align: center;
     top: 200px;
     left: 30%
}

.photoBody  h1 {
    font: 30px/36px Futura Hv BT;
    letter-spacing: 0px;
}

.photoBody  h4 {
    font: 30px/36px Futura Lt BT;
    margin-top: 60px;
}

.filesList{
    padding: 26px;
}


.filesList li{
    list-style-type: none;
    margin-bottom: 14px;
    font: 18px/18px Futura Lt BT;
}

.photoThumbs img{
    width: 133px;
    height: 100px;
    border-radius: 27px;
    float:left;
    margin: 7px;
    object-fit: cover;
}

.photoPreview{
    margin-top: 20px;
    width: 760px;
    height: 340px;
    overflow: auto;
    margin-left: 25px;
    position: relative;
 
}

.previewBottom{
    position: absolute;
    bottom: 48px;
    right: 65px;
}

.photoThumbs video{
    width: 133px;
    height: 100px;
    border-radius: 27px;
    float:left;
    margin: 7px;
}

.photoThumbs .playBtn{
    /* color: red; */
     color: rgb(248, 248, 248);
     height: 40px;
     width: 40px;
     position: absolute;
     margin-left: 50px;
     margin-top: 35px;
}